import isFunction from 'lodash/isFunction';
import { clearLocalStorage } from './paypal-utils';

const cancel = (callback) => {
	/* eslint-disable-next-line  no-unused-vars */
	return (data, actions) => {
		// Show a cancel page, or return to cart
		// Call endpoint to clear address
		clearLocalStorage();

		//This call back can be used to hide a loading spinner
		if (isFunction(callback)) {
			callback(false);
		}
	};
};

export default cancel;
