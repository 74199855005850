import get from 'lodash/get';
import { PAYPAL_API_URLS } from './paypal-constants';
import { setSessionUrls, clearLocalStorage } from './paypal-utils';
import request from './request';

/* eslint-disable-next-line  no-unused-vars */
const createOrder = (data, actions) => {
	//Clear Previous PayPal State If Set
	clearLocalStorage();

	return request(PAYPAL_API_URLS.create, 'POST', data)
		.then((response) => {
			if (get(response, 'sessionUrls')) {
				setSessionUrls(get(response, 'sessionUrls'));
			}

			return get(response, 'token');
		}).catch(() => {
			return; // failed PayPal flow
		});
};

export default createOrder;
