/* globals jQuery */
import onReady from 'public/js/deps/main';

const THE_SCRIPT = function () {
	onReady();
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	if (typeof __CURRENT_SCRIPT__ === 'undefined') {
		throw new Error('__CURRENT_SCRIPT__ is not defined');
	}
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}

/*
	Fix for jQuery v3. Makes touch and wheel event listeners as `passive` to improve page's scroll performance.
	Clears Lighthouse's Performance alert.
*/
jQuery.event.special.touchstart = {
	setup: function( _, ns, handle ) {
		this.addEventListener('touchstart', handle, { passive: !ns.includes('noPreventDefault') });
	}
};
jQuery.event.special.touchmove = {
	setup: function( _, ns, handle ) {
		this.addEventListener('touchmove', handle, { passive: !ns.includes('noPreventDefault') });
	}
};
jQuery.event.special.wheel = {
	setup: function( _, ns, handle ){
		this.addEventListener('wheel', handle, { passive: true });
	}
};
jQuery.event.special.mousewheel = {
	setup: function( _, ns, handle ){
		this.addEventListener('mousewheel', handle, { passive: true });
	}
};
