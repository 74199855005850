const $onReady = require('public/js/lib/on-ready');
const getWishlist = () => import(/* webpackChunkName: "get-wishlist" */ 'public/js/components/wishlist');
const wishlistSelector = [
	'.add-to-wishlist',
	'.remove-from-wishlist',
	'.remove-all-from-wishlist'
];

const  handleWishListModule = () => {
	//Only Add the wishlist actions to the page if there is at least 1 add to wishlist button.
	const wishListButtons = document.querySelector(wishlistSelector.join(','));
	if (wishListButtons) {
		getWishlist().then((module) => {
			if (window.__IS_WEBSTORE_PLUGIN) {
				module.attachEvents();
			}
		});
	}
};

$onReady({rp$, window,
	label: 'get-wishlist',
	fn: function () {
		handleWishListModule();
	},
});
