'use strict';

import paypal from 'public/js/components/paypal';
import paypalSmartButtons from 'public/js/components/paypal-smart-buttons/index';

const initPaypalButtons = () => {
	rp$('.cart-listing').on('click', '.paypal-button', (e) => {
		paypal.doPaypalCheckout(e);
	});

	const paypalFlyoutContainerClass = '.paypal-smart-flyout-button';
	paypalSmartButtons.initSmartButtons(paypalFlyoutContainerClass, { 
		loadingCallback: (show) => {
			if (show) {
				rp$('body').overlaySpinner();
			} else {
				rp$('body').overlaySpinner.remove();
			}
		}
	});
};

export default {
	initPaypalButtons,
};
