'use strict';

const rp_app = window.rp_app || {};

rp_app.track = require('public/js/lib/track');
rp_app.logger = require('public/js/lib/logger');
rp_app.notify = require('public/js/lib/notify');
rp_app.query = require('public/js/lib/query');
// Allow for plugin to override the window object
rp_app.location = window.$location || require('public/js/lib/location')(window);

module.exports = rp_app;
