'use strict';

const get = require('lodash/get');
const invert = require('lodash/invert');

const ERRORS = {
	CHECKOUT_APP_SESSION_EXPIRED: 6007,
};

const ERRORS_BY_CODE = invert(ERRORS);

ERRORS.getErrorKey = (errorCode) => {
	return get(ERRORS_BY_CODE, errorCode);
};

ERRORS.isRecognizedError = (errorMessages) => {
	return find(errorMessages, (err) => {
		!!ERRORS.getErrorKey(err.code);
	});
};

module.exports = Object.freeze(ERRORS);
