'use strict';

const CSRF_NAME_FIELD_ID = '__csrf_name_field__';
const CSRF_VALUE_FIELD_ID = '__csrf_value_field__';

/**
 * A CSRF key pair that is used to create a csrf response
 * @typedef {object} CsrfFieldPair
 * @property {string} key
 * @property {string} value
 */

/**
 * An object that client code mirrors backend CSRF state
 * @typedef {object} CsrfToken
 * @property {CsrfFieldPair} name Name/Value pair use to create a name field for the token
 * @property {CsrfFieldPair} token Name/Value pair use to create a value field for the token
 */

/**
 * Get a CSRF token object
 * Depends on document global to grab hidden inputs
 * @param {Window} window
 * @returns {CsrfToken}
 */
const getToken = () => {
	const csrf = {
		name: {
			key: '',
			value: '',
		},
		token: {
			key: '',
			value: '',
		},
	};

	const nameField = document.getElementById(CSRF_NAME_FIELD_ID);
	const valueField = document.getElementById(CSRF_VALUE_FIELD_ID);

	// We only want a token if we have all four values
	if (nameField && valueField) {
		const nameName = nameField.getAttribute('name');
		const nameValue = nameField.getAttribute('value');
		if (nameName && nameValue) {
			csrf.name.key = nameName;
			csrf.name.value = nameValue;
		}

		const valueName = valueField.getAttribute('name');
		const valueValue = valueField.getAttribute('value');
		if (valueName && valueValue) {
			csrf.token.key = valueName;
			csrf.token.value = valueValue;
		}
	}

	return csrf;
};

/**
 * Validate that a token is usable. This currently just checks that props are non-falsy
 * @param {CsrfToken} token
 * @returns {boolean}
 */
const isValidToken = (token) => {
	if (!token.name || !token.token) {
		return false;
	}
	if (!token.name.key || !token.name.value) {
		return false;
	}
	if (!token.token.key || !token.token.value) {
		return false;
	}

	return true;
};

/**
 * Gets a list of all CSRF token key/value pairs. Initially used for getting the token ready for usage by XHR/ajax
 * @param {CsrfToken} token
 * @returns {CsrfFieldPair[]} Array of key/value pairs to be used in request data
 */
const getCsrfRequestData = (token) => {
	return [
		{[token.name.key]: token.name.value},
		{[token.token.key]: token.token.value},
	];
};

module.exports = {
	getCsrfNameFieldId() {
		return CSRF_NAME_FIELD_ID;
	},
	getCsrfValueFieldId() {
		return CSRF_VALUE_FIELD_ID;
	},
	getToken,
	getCsrfRequestData,
	isValidToken,
};
