// wrappers for local storage APIs

const getItem = (key) => {
	try {
		return window.localStorage.getItem(key);
	} catch(e) {
		return null;
	}
};

const setItem = (key, value) => {
	try {
		return window.localStorage.setItem(key, value);
	} catch(e) {
		return null;
	}
};

const removeItem = (key) => {
	try {
		return window.localStorage.removeItem(key);
	} catch(e) {
		return null;
	}
};

module.exports = {
	getItem,
	setItem,
	removeItem,
};
